// NOTE: this file should not contain any import alias, as it's used in next.config.ts
//  please use relative path

export const RootPath = 'p'
export const CategoriesDestinationRoute = 'category'
export const ProductDestinationRoute = 'product'
export const DestinationRoutes = [CategoriesDestinationRoute, ProductDestinationRoute] as const

export type DestinationRoute = (typeof DestinationRoutes)[number]

/**
 * {
 *  <locale>: {
 *    <source>: <destination>
 *  }
 * }
 */
export type RoutingMap = Record<string, Record<string, DestinationRoute>>

const genericLangRoutingMaps = {
  de: {
    produkte: 'category',
    produkt: 'product',
  },
  el: {
    proionta: 'category',
    proion: 'product',
  },
  en: {
    products: 'category',
    product: 'product',
  },
  es: {
    productos: 'category',
    producto: 'product',
  },
  fr: {
    produits: 'category',
    produit: 'product',
  },
  it: {
    prodotti: 'category',
    prodotto: 'product',
  },
  nl: {
    producten: 'category',
    product: 'product',
  },
  pt: {
    produtos: 'category',
    produto: 'product',
  },
  ro: {
    produse: 'category',
    produs: 'product',
  },
  ru: {
    produkty: 'category',
    produkt: 'product',
  },
} as const

/**
 * {
 *  <locale>: {
 *    <source>: <destination>
 *  }
 * }
 */
export const Routing: RoutingMap = {
  'ar-eg': genericLangRoutingMaps.en,
  'az-az': {
    mehsullar: 'category',
    mehsul: 'product',
  },
  'bg-bg': {
    produkti: 'category',
    produkt: 'product',
  },
  'cs-cz': {
    vyrobky: 'category',
    vyrobek: 'product',
  },
  'da-dk': {
    produkter: 'category',
    produkt: 'product',
  },
  'de-at': genericLangRoutingMaps.de,
  'de-de': genericLangRoutingMaps.de,
  'el-cy': genericLangRoutingMaps.el,
  'el-gr': genericLangRoutingMaps.el,
  'en-eg': genericLangRoutingMaps.en,
  'en-dz': genericLangRoutingMaps.en,
  'en-gb': genericLangRoutingMaps.en,
  'en-my': genericLangRoutingMaps.en,
  'en-ph': genericLangRoutingMaps.en,
  'en-tz': genericLangRoutingMaps.en,
  'en-vn': genericLangRoutingMaps.en,
  'es-es': genericLangRoutingMaps.es,
  'et-ee': {
    tooted: 'category',
    toode: 'product',
  },
  'fi-fi': {
    tuotteet: 'category',
    tuote: 'product',
  },
  'fr-be': genericLangRoutingMaps.fr,
  'fr-dz': genericLangRoutingMaps.fr,
  'fr-fr': genericLangRoutingMaps.fr,
  'fr-lu': genericLangRoutingMaps.fr,
  'fr-ma': genericLangRoutingMaps.fr,
  'fr-tn': genericLangRoutingMaps.fr,
  'he-il': {
    mutsarim: 'category',
    mutsar: 'product',
  },
  'hr-hr': {
    proizvodi: 'category',
    proizvod: 'product',
  },
  'hr-ba': {
    proizvodi: 'category',
    proizvod: 'product',
  },
  'hu-hu': {
    termekek: 'category',
    termek: 'product',
  },
  'hy-am': {
    artadranqner: 'category',
    artadranq: 'product',
  },
  'it-it': genericLangRoutingMaps.it,
  'ka-ge': {
    produqtebi: 'category',
    produqti: 'product',
  },
  'lt-lt': {
    produktai: 'category',
    produktas: 'product',
  },
  'lv-lv': {
    produkti: 'category',
    produkts: 'product',
  },
  'me-me': {
    proizvodi: 'category',
    proizvod: 'product',
  },
  'nl-be': genericLangRoutingMaps.nl,
  'nl-nl': genericLangRoutingMaps.nl,
  'no-no': {
    produkter: 'category',
    produkt: 'product',
  },
  'pt-pt': genericLangRoutingMaps.pt,
  'ro-ro': genericLangRoutingMaps.ro,
  'sq-al': {
    produkteve: 'category',
    produkt: 'product',
  },
  'sq-xk': {
    produkteve: 'category',
    produkt: 'product',
  },
  'sk-sk': {
    produkty: 'category',
    produkt: 'product',
  },
  'sl-si': {
    izdelki: 'category',
    izdelek: 'product',
  },
  'sr-rs': {
    proizvodi: 'category',
    proizvod: 'product',
  },
  'sv-se': {
    produkter: 'category',
    produkt: 'product',
  },
  'tr-tr': {
    urunler: 'category',
    urun: 'product',
  },
  'vi-vn': {
    'cac-san-pham': 'category',
    'san-pham': 'product',
  },
  'de-ch': genericLangRoutingMaps.de,
  'fr-ch': genericLangRoutingMaps.fr,
  'it-ch': genericLangRoutingMaps.it,
  'mk-mk': {
    proizvodi: 'category',
    proizvod: 'product',
  },
  'pt-br': genericLangRoutingMaps.pt,
  'ko-kr': genericLangRoutingMaps.en,
  'ja-jp': genericLangRoutingMaps.en,
  'es-cl': genericLangRoutingMaps.es,
  'es-co': genericLangRoutingMaps.es,
  'es-ar': genericLangRoutingMaps.es,
  'en-in': genericLangRoutingMaps.en,
  'en-ae': genericLangRoutingMaps.en,
  'en-au': genericLangRoutingMaps.en,
  'pl-pl': {
    produkty: 'category',
    produkt: 'product',
  },
  'kk-kz': {
    onimder: 'category',
    onim: 'product',
  },
  'en-hk': genericLangRoutingMaps.en,
  'ro-md': genericLangRoutingMaps.ro,
  'ru-kz': genericLangRoutingMaps.ru,
  'ru-md': genericLangRoutingMaps.ru,
  'ru-uz': genericLangRoutingMaps.ru,
  'en-sg': genericLangRoutingMaps.en,
  'uz-uz': {
    mahsulotlar: 'category',
    mahsulot: 'product',
  },
  'en-nz': genericLangRoutingMaps.en,
  'uk-ua': {
    produkty: 'category',
    produkt: 'product',
  },
  'id-id': {
    katalog: 'category',
    produk: 'product',
  },
  'en-za': genericLangRoutingMaps.en,
  'th-th': genericLangRoutingMaps.en,
  'en-th': genericLangRoutingMaps.en,
  'en-ww': genericLangRoutingMaps.en,
} as const

export const InvertedRouting = Object.entries(Routing).reduce((acc, [locale, route]) => {
  const localeInvertedRouting = Object.entries(route).reduce(
    (acc2, [source, destination]) => ({
      ...acc2,
      [destination]: source,
    }),
    {} as Record<(typeof DestinationRoutes)[number], string>,
  )

  return {
    ...acc,
    [locale]: localeInvertedRouting,
  }
}, {} as Record<string, Record<(typeof DestinationRoutes)[number], string>>)
